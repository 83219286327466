

























import { Vue, Prop, Component } from "vue-property-decorator";
import { delay } from "@/models/utilities/timer";

@Component
export default class extends Vue {
    @Prop({ default: [] }) readonly routes!: [];
    @Prop({ default: "red" }) readonly caretColor!: string;
    // #region private fields
    private lastTop = 0;
    // #endregion

    // #region private methods
    /**
     * DOMのロードが完了したとき.
     */
    private async mounted() {
        const name = this.$route.path.split("/")[1] || this.$route.name;
        if (name) {
            await delay(1000);
            this.moveCaretPositionFromName(name);
        }
    }

    /**
     * ルーティングの名前でCaretを移動します.
     * @param name
     */
    private moveCaretPositionFromName(name: string): void {
        if (this.$refs[name]) {
            const element = (this.$refs[name] as Vue[])[0].$el as Element;
            if (element) {
                this.moveCaretPosition(element, 6);
                return;
            }
            throw new Error("指定したルーティングまたはエレメントが存在しません.");
        }
        else {
            throw new Error("指定したルーティングまたはエレメントが存在しません.");
        }
    }

    /**
     * Menu item をクリックしたとき.
     * @param e
     */
    private clickItem(e: MouseEvent, name: string, path: string): void {
        if (!this.$route.path.includes(name) || (this.$route.name === "top" && name !== "top")) {
            this.$router.push(path);
        }

        const margin = 6;
        const element = e.currentTarget as Element;
        if (element) {
            this.moveCaretPosition(element, margin);
        }
    }

    /**
     * ナビゲーションのCaretを指定したエレメントの位置に移動します.
     * @param targetElement 移動先のエレメント
     */
    private moveCaretPosition(targetElement: Element, margin: number): void {
        const parentRect = this.$el.getBoundingClientRect();
        const rect = targetElement.getBoundingClientRect();
        const style = (this.$refs.rect as HTMLDivElement).style;
        if (this.lastTop < rect.top) {
            setTimeout(() => (style.top = `${rect.top - parentRect.top + margin}px`), 150);
            style.bottom = `calc(100% - ${rect.bottom - parentRect.top - margin}px`;
        }
        else {
            setTimeout(() => (style.bottom = `calc(100% - ${rect.bottom - parentRect.top - margin}px`), 150);
            style.top = `${rect.top - parentRect.top + margin}px`;
        }

        this.lastTop = rect.top;
    }
}
