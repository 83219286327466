



















































import { Vue, Component, Prop } from "vue-property-decorator";
import { AuthService } from "@/models/auth/AuthService";
import Enumerable from "linq";
import { config } from "@/config";
import Axios from "axios";

/**
 * アカウント情報を表示するためのカードを提供します.
 */
@Component({ components: {} })
export default class HistoryItem extends Vue {
    @Prop({ default: () => new AuthService() }) readonly authService !: AuthService;
    private logout() {
        this.authService.logout();
        location.reload();
    }

    private getGroupName(groupId: number) {
        const group = Enumerable.from(this.authService.groupIds)
            .where(x => x.id === groupId)
            .firstOrDefault();
        if (group) {
            return group.name;
        }
        return "r";
    }
}
