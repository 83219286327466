








































































































import { Vue, Component, Provide } from "vue-property-decorator";
import NavigationBar from "@/components/Managements/Common/NavigationBar.vue";
import AutheticatedFrame from "../Commons/AutheticatedFrame.vue";
import { routes } from "@/router/ManagementRouter";
import { container } from "tsyringe";
import Enumerable from "linq";
import { ConfirmDialog, ConfirmDialogContent, InputDialog } from "ui-gallery";
import AccountCard from "@/components/Managements/Common/AccountCard.vue";
import Axios from "axios";
import { SuccessResponse } from "../../models/api/Response";
import { SettingService } from "../../models/settings/SettingService";
import { AuthService } from "@/models/auth/AuthService";
import { RoomsManagementService } from "@/models/room/RoomManagementsService";
import { getIsInstalled, install } from "@/installer";

/**
 * ZENTALKの管理画面を提供します．
 */
@Component({
    components: {
        NavigationBar,
        AutheticatedFrame,
        AccountCard,
        ConfirmDialog,
        InputDialog
    }
})
export default class ManagementsMain extends Vue {
    // #region private fields
    private drawer = null;
    private width = 0;
    private routes = routes;
    public readonly authService: AuthService = container.resolve(AuthService);
    public readonly roomManagementsService = container.resolve(RoomsManagementService);
    private readonly settingService = container.resolve(SettingService);
    // #endregion

    private get isInstalled() {
        return getIsInstalled();
    }

    private install = install;

    // #region injects
    private notifyMessage = "";
    private notifyColor = "";
    private notifyTimeout = 8000;
    private isShowNotify = false;

    @Provide()
    public async confirm(message: string, text = "", okText = "OK", cancelText = "キャンセル"): Promise<boolean> {
        const confirmDialog = this.$refs.confirmDialog as any | undefined;
        if (!confirmDialog) return false;
        return (await confirmDialog.showAsync(new ConfirmDialogContent({
            title: message,
            text,
            okText,
            cancelText
        }))) as boolean;
    }

    @Provide()
    public async message(message: string, text = "", okText = "OK", cancelText = "キャンセル"): Promise<void> {
        const confirmDialog = this.$refs.confirmDialog as any | undefined;
        if (!confirmDialog) return;
        await confirmDialog.showAsync(new ConfirmDialogContent({
            title: message,
            text,
            okText,
            cancelText,
            hideCancelButton: true
        }));
    }

    @Provide()
    public notify(message: string, timeout = 4000, color = "primary") {
        this.notifyColor = color;
        this.notifyTimeout = timeout;
        this.notifyMessage = message;
        this.isShowNotify = true;
    }

    @Provide()
    public async input(message: string, initialValue = ""): Promise<string> {
        const inputDialog = this.$refs.inputDialog as any | undefined;
        if (!inputDialog) return "";
        const text = await inputDialog.showAsync({ text: message, initialValue }) as string;
        return text;
    }
    // #endregion

    // #region private methods
    /**
     * コンポーネントが生成された時に実行されます．
     */
    private async created() {
        window.addEventListener("resize", this.onResize);
        this.roomManagementsService.serve(this.authService.currentGroupId, this.authService.token);
        this.onResize();
        this.settingService.fetchSettings();
        this.authService.fetchMembers();
    }

    /**
     * ログアウトします．
     */
    private logout() {
        this.authService.logout();
        location.reload();
    }

    /**
     * Windowがリサイズされたときに実行されます．
     */
    private onResize() {
        this.width = window.innerWidth;
    }

    /**
     * グループIDからフォルダ名を取得します．
     * @param groupId グループID
     */
    private getGroupName(groupId: number) {
        const group = Enumerable.from(this.authService.groupIds)
            .where(x => x.id === groupId)
            .firstOrDefault();
        if (group) {
            return group.name;
        }
        return "";
    }
    // #endregion
}
