var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AutheticatedFrame',[_c('v-app-bar',{attrs:{"height":"56","elevation":1,"fixed":"","app":"","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"ml-3 logo-img",staticStyle:{"height":"40px","background":"transparent"},attrs:{"src":require("@/assets/title.png")}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"target":"_blank","link":"","href":"/新ZEN TALKマニュアル　Ver. 2.0.pdf","color":"primary","text":""}},[_vm._v("操作マニュアル")]),_c('div',{staticStyle:{"border-right":"1px solid #d2d2d2","height":"28px"}}),(_vm.isInstalled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.install()}}},on),[_c('v-icon',[_vm._v("mdi-download-box-outline")])],1)]}}],null,false,3676564806)},[_c('span',[_vm._v("ホーム画面に追加する")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","color":"primary"},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}])},[_c('span',[_vm._v("ログアウト")])])],1)],1),_c('v-navigation-drawer',{staticClass:"navbar-background",attrs:{"app":"","clipped":"","width":"280"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-menu',{attrs:{"right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"justify-start d-flex align-center",staticStyle:{"height":"80px","transition":"all 0.5s","cursor":"pointer"},attrs:{"text":"","block":""}},on),[_c('div',[_c('div',{staticClass:"ml-1",staticStyle:{"height":"40px","width":"40px","color":"white","background":"rgb(81, 39, 119)","border-radius":"24px","font-size":"26px","text-align":"center"}},[_vm._v(_vm._s(_vm.authService.memberName[0]))])]),_c('div',{staticClass:"ml-2 pr-1",staticStyle:{"width":"calc(100% - 60px)"}},[_c('div',{staticClass:"text-truncate",staticStyle:{"font-size":"24px","text-align":"left"}},[_vm._v(_vm._s(_vm.authService.memberName))]),_c('div',{staticClass:"text-truncate",staticStyle:{"color":"rgba(120, 120, 120, 0.8)","text-align":"left"}},[_vm._v(_vm._s(_vm.authService.loginId))])])])]}}])},[_c('AccountCard',{attrs:{"authService":_vm.authService}})],1),_c('v-divider'),_c('NavigationBar',{attrs:{"routes":_vm.routes}})],1),_c('v-main',[_c('div',{staticClass:"background strech"},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('keep-alive',[_c('router-view')],1)],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.notifyColor,"timeout":_vm.notifyTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","fab":""},on:{"click":function($event){_vm.isShowNotify = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.isShowNotify),callback:function ($$v) {_vm.isShowNotify=$$v},expression:"isShowNotify"}},[_vm._v(" "+_vm._s(_vm.notifyMessage)+" ")]),_c('ConfirmDialog',{ref:"confirmDialog",staticStyle:{"z-index":"99999"}}),_c('InputDialog',{ref:"inputDialog"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }